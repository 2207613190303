// import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
// import { renderRule, StructuredText } from "react-datocms"
// import { isParagraph } from "datocms-structured-text-utils"
//
// import SectionTitle from "../SectionTitle"
// import { Container } from "../UI"
//
// const GivingNature = ({ donationSectionTitle, message, fullWidth }) => {
//   return (
//     <section className="mt-[-40px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
//       <Container className="relative">
//         <SectionTitle>{donationSectionTitle}</SectionTitle>
//         <div className="mx-auto mb-10 max-w-[814px] rounded-2xl bg-white/30 py-6 shadow-lg backdrop-blur-xl">
//           <div
//             className={`${
//               fullWidth ? "" : "mx-auto max-w-[420px] "
//             } px-6 font-spartan leading-[25.92px]`}
//           >
//             <StructuredText
//               data={message}
//               customRules={[
//                 renderRule(isParagraph, ({ children, key }) => {
//                   if (children[0].length === 0) return <br />
//                   return (
//                     <p key={key} className="my-4">
//                       {children}
//                     </p>
//                   )
//                 }),
//               ]}
//             />
//           </div>
//         </div>
//       </Container>
//     </section>
//   )
// }
//
// export default GivingNature

const GivingNature = () => {
  return null
}

export default GivingNature
