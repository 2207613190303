import React from "react"
import { SwiperSlide } from "swiper/react"

import ReactCarousel from "../ReactCarousel"
import { Container, SlideCard } from "../UI"

const SliderSection = ({ reviewSectionTitle, reviewSlide }) => {
  return (
    <section className="relative z-0 mt-[128px] mb-[128px] overflow-hidden pt-8 pb-10 md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      <Container className="slider_section relative z-10">
        <div className="md:pl-8">
          <h2 className="mb-10 font-spartan text-2xl font-bold text-white">
            {reviewSectionTitle}
          </h2>
          <ReactCarousel>
            {reviewSlide.map(slide => (
              <SwiperSlide key={slide.id}>
                <SlideCard
                  author={slide.author}
                  location={slide.location}
                  review={slide.review}
                />
              </SwiperSlide>
            ))}
          </ReactCarousel>
        </div>
      </Container>
    </section>
  )
}

export default SliderSection
