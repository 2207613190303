// import React from "react"
// import { GatsbyImage } from "gatsby-plugin-image"
//
// import { useInstagramData } from "../../system/DatoCms/InstagramData"
// import { LayoutContainer } from "../UI"
// import { InstagramNodeLogo } from "../UI/Svgs"
//
// const InstagramNodes = () => {
//   const data = useInstagramData()
//   return (
//     <section className="mt-[128px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
//       <LayoutContainer className="grid place-items-center">
//         <div>
//           <div className="mb-7 flex items-center justify-center md:mb-9 md:justify-start">
//             <InstagramNodeLogo className="mr-[10px] h-10 w-10" />
//             <div className="font-spartan text-gray-1">
//               <p className="text-xs font-bold">Follow us on Instagram</p>
//               <a
//                 href="https://www.instagram.com/toucangiving"
//                 target="_blank"
//                 rel="noreferrer"
//                 className="block text-[10px]"
//               >
//                 @toucangiving
//               </a>
//             </div>
//           </div>
//           <div className="inline-grid grid-cols-2 justify-center md:grid-cols-4 md:gap-5">
//             {React.Children.toArray(
//               data.map((d, i) => (
//                 <InstaCard
//                   index={i}
//                   image={d.localFile.childImageSharp.gatsbyImageData}
//                   id={d.id}
//                   caption={d.caption}
//                   permalink={d.permalink}
//                 />
//               ))
//             )}
//           </div>
//         </div>
//       </LayoutContainer>
//     </section>
//   )
// }
//
// export default InstagramNodes
//
// const InstaCard = ({ image, caption, id, permalink, index }) => {
//   return (
//     <a
//       href={permalink}
//       target="_blank"
//       rel="noreferrer"
//       className={`${
//         index >= 4 ? "hidden md:block" : ""
//       } h-[155px] w-[155px] md:h-[188px] md:w-[188px]`}
//     >
//       <GatsbyImage
//         image={image}
//         title={caption}
//         alt={id}
//         className="h-full w-full"
//       />
//     </a>
//   )
// }

const InstagramNodes = () => {
  return null
}

export default InstagramNodes