import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"

const GivingStepsSection = ({ data }) => {
  return (
    <section className="mt-[-170px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      {React.Children.toArray(
        data.map(d => (
          <OrangSection
            id={d.id}
            title={d.title}
            subTitle={d.subtitle}
            description={d.description}
            image={d.image}
            isOrange={d.orangeSection}
          />
        ))
      )}
    </section>
  )
}

export default GivingStepsSection

const OrangSection = ({
  id,
  title,
  subTitle,
  description,
  image,
  isOrange,
}) => {
  return (
    <div
      className={`overflow-hidden font-spartan ${
        isOrange ? "bg-yellow-gradient" : "bg-white bg-opacity-70"
      } `}
    >
      <div className="mx-auto max-w-screen-2xl md:flex">
        <LayoutContainer
          className={`grid place-items-center py-12 md:w-1/2 ${
            isOrange ? "order-1" : "order-2"
          }`}
        >
          <div
            className={`mx-auto md:max-w-[420px] ${
              isOrange ? "text-white" : "text-black"
            }`}
          >
            <h2 className="mb-[10px] text-[12px] font-bold uppercase leading-[19px] tracking-[5px]">
              {title}
            </h2>
            <h3 className="mb-[10px] text-2xl font-bold leading-[39px]">
              {subTitle}
            </h3>
            <StructuredText
              data={description}
              customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                  if (children[0].length === 0) return <br />
                  return (
                    <p
                      key={key}
                      className="font-spartan text-base leading-[25.92px]"
                    >
                      {children}
                    </p>
                  )
                }),
              ]}
            />
          </div>
        </LayoutContainer>
        <div
          className={`grid place-items-center md:w-1/2 ${
            isOrange ? "order-2" : "order-1"
          }`}
        >
          {(() => {
            switch (id) {
              case "DatoCmsGivingstepsblock-82784322-en":
                return (
                  <div className="lg:pt-12">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.alt}
                      className="h-[254px] w-[375px] md:h-full md:w-full lg:h-[430px] lg:w-[640px]"
                    />
                  </div>
                )

              case "DatoCmsGivingstepsblock-82784323-en":
                return (
                  <div className="pb-12 pt-4 md:py-20">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.alt}
                      className="h-[242px] w-[254px] md:h-full md:w-full lg:h-[330px] lg:w-[347px]"
                    />
                  </div>
                )
              case "DatoCmsGivingstepsblock-82784324-en":
                return (
                  <div className="pb-12 md:pt-32 md:pb-28">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.alt}
                      className="h-[160 px] w-[307px] md:h-full md:w-full lg:h-[258px] lg:w-[494px]"
                    />
                  </div>
                )
              case "DatoCmsGivingstepsblock-82784325-en":
                return (
                  <div className="md:pt-16 ">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.alt}
                      className="h-[498px] w-[312px] md:h-full md:w-full lg:h-[436px] lg:w-[276px]"
                    />
                  </div>
                )
              case "DatoCmsGivingstepsblock-82784326-en":
                return (
                  <div className="pb-12 md:mb-0 md:py-36">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.alt}
                      className="h-[130px] w-[340px] md:h-full md:w-full lg:h-[192px] lg:w-[500px]"
                    />
                  </div>
                )

              default:
                return <div>You are a User.</div>
            }
          })()}
        </div>
      </div>
    </div>
  )
}
