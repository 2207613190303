import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"

const AllYourGiving = ({ topImage, title, image, description }) => {
  return (
    <section className="mt-[128px] mb-[128px] font-spartan md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      <LayoutContainer>
        {topImage?.gatsbyImageData && (
          <div className="mx-auto mb-[70px] h-[311px] w-[311px] md:mb-[128px] md:hidden">
            <GatsbyImage
              image={topImage?.gatsbyImageData}
              alt={topImage?.alt}
              className="h-full w-full"
            />
          </div>
        )}

        <div className="items-center md:flex md:justify-center">
          <div className="md:w-[414px]">
            <h2 className="mb-2 text-[36px] font-bold leading-[50px] text-gray-1">
              {title}
            </h2>
            <div className="mb-2 md:mb-0">
              <StructuredText
                data={description}
                customRules={[
                  renderRule(isParagraph, ({ children, key }) => {
                    if (children[0].length === 0) return <br />
                    return (
                      <p
                        key={key}
                        className="text-base leading-[26px] text-gray-1"
                      >
                        {children}
                      </p>
                    )
                  }),
                ]}
              />
            </div>
          </div>
          {image?.gatsbyImageData && (
            <div className="h-[199px] w-[311px]">
              <GatsbyImage
                image={image?.gatsbyImageData}
                alt={image?.alt}
                className="h-full w-full"
              />
            </div>
          )}
        </div>
      </LayoutContainer>
    </section>
  )
}

export default AllYourGiving
