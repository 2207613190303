import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import Hero from "../components/Hero"
import AllYourGiving from "../components/AllYourGivingPage"
import GivingStepsSection from "../components/GivingStepsSection"
import SliderSection from "../components/SliderSection"
import DownloadAppSection from "../components/DownloadAppSection"
import InstagramNodes from "../components/InstagramNodes"
import GivingNature from "../components/GivingNature"
// import DynamicPageForm from "../components/DynamicPageForm/DynamicPageForm"
import { Main } from "../components/UI"

// Dato CMS Content
import {
  useReviewData,
  useHeroData,
  useAllYourGivingData,
  useGivingStepsSection,
  useMessageData,
  // useFormData,
} from "../system/DatoCms/HomepageData"

const IndexPage = ({ data }) => {
  const { noIndex, seo } = data.allDatoCmsHomepage.nodes[0]
  const { reviewSectionTitle, reviewSlide } = useReviewData()
  const {
    data: {
      title,
      showDownloadAppButton,
      iosDownloadLink,
      androidDownloadLink,
      heroPhones,
      backgroundImage,
      description,
    },
  } = useHeroData()
  const {
    topImage,
    title: givingTitle,
    image: givingImage,
    description: givingDescription,
  } = useAllYourGivingData()
  const givingStepsData = useGivingStepsSection()
  // const formData = useFormData()
  const messageData = useMessageData()
  const canonicalUrl = `https://thetoucan.app/`
  return (
    <Layout
        isHomePage
        showSecondaryFooter>
      <Seo
        title={seo.title}
        description={seo.description}
        noIndex={noIndex}
        canonicalUrl={canonicalUrl}
      />
      <Main
        className="bg-center"
        style={{
          backgroundImage:
            "url(`https://www.datocms-assets.com/54391/1638856126-background-1.png`)",
        }}
      >
        <Hero
          title={title}
          showDownloadAppButton={showDownloadAppButton}
          iosDownloadLink={iosDownloadLink}
          androidDownloadLink={androidDownloadLink}
          heroPhones={heroPhones}
          backgroundImage={backgroundImage}
          description={description}
          showVideoButton={true}
        />
        <AllYourGiving
          topImage={topImage}
          title={givingTitle}
          image={givingImage}
          description={givingDescription}
        />
        <GivingStepsSection data={givingStepsData} />
        <GivingNature
          donationSectionTitle={messageData.title}
          message={messageData.message}
        />
        <SliderSection
          reviewSectionTitle={reviewSectionTitle}
          reviewSlide={reviewSlide}
        />
        <DownloadAppSection />
        {/*<DynamicPageForm data={formData.data} />*/}
        <InstagramNodes />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsHomepage {
      nodes {
        noIndex
        seo {
          title
          description
        }
      }
    }
  }
`

export default IndexPage
